export const oneMoreHour = (start: string, end: string) => {
	let startValue = roundToNearestThirty(start);
	let finalValue = roundToNearestThirty(end);

	if (startValue === finalValue) {
		return [startValue, addOneHour(startValue)];
	}

	let arr = [startValue];
	let currentTime = startValue;

	while (currentTime !== finalValue) {
		currentTime = addOneHour(currentTime);
		arr.push(currentTime);
	}

	arr.push(addOneHour(finalValue));

	return arr;
};

export   const oneDayMore = (dataString: string) => {
	const part = dataString.split("/");
	const year = parseInt(part[0], 10);
	const month = parseInt(part[1], 10) - 1;
	const day = parseInt(part[2], 10);

	const date = new Date(year, month, day);

	date.setDate(date.getDate() + 1);

	const newYear = date.getFullYear();
	const newMonth = (date.getMonth() + 1).toString().padStart(2, "0");
	const newDay = date.getDate().toString().padStart(2, "0");

	return `${newYear}/${newMonth}/${newDay}`;
};

const roundToNearestThirty = (time: string): string => {
	let [hour, minutePeriod] = time.split(":");
	let [minutes, period] = minutePeriod.split(" ");
	let hourNumber = Number(hour);
	let minutesNumber = Number(minutes);

	if (minutesNumber > 30) {
		hourNumber += 1;
		minutesNumber = 30;
	} else {
		minutesNumber = 30;
	}

	if (hourNumber > 12) {
		hourNumber -= 12;
		period = period === "AM" ? "PM" : "AM";
	} else if (hourNumber === 12 && period === "AM") {
		period = "PM";
	} else if (hourNumber === 12 && period === "PM") {
		period = "AM";
	}

	return `${hourNumber}:${minutesNumber
		.toString()
		.padStart(2, "0")} ${period}`;
};


const addOneHour = (time: string): string => {
	let [hours, minutes] = time.split(":");
	let period = minutes.slice(-2); 
	minutes = minutes.slice(0, 2);
	let hour = parseInt(hours);

	if (hour === 12) {
		period = period === "AM" ? "PM" : "AM";
	}

	hour = hour % 12 + 1; 

	return `${hour}:${minutes} ${period}`;
};
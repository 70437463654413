import React, { ReactNode } from "react";
import "./styles.scss";
import ArrowLeft from "../../../assets/icons/arrow-left-gray.svg";

interface ButtonProps {
  children: ReactNode;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quintenary";
  arrowIcon?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	disabled?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { buttonStyle, children, arrowIcon, onClick, disabled } = props;
  return (
    <button
      id="button"
      className={`${buttonStyle}${
        buttonStyle === "quintenary" ? " background-blur-gray " : ""
      }`}
			onClick={onClick}
			disabled={disabled}
    >
      {children}
      {arrowIcon && <img src={ArrowLeft} alt="arrow left" />}
    </button>
  );
};

export default Button;

import axios from "axios";
import { apiServices } from "../apiServices";

export type SendEmailBody = {
  name: string;
  client_phone: string;
  client_email: string;
  boat: string;
  hour: number;
  number_passenger: number;
  start_date: string;
  end_date: string;
  google_calendar_url: string;
  to: string;
  via_link: string[] | undefined;
};

export const sendEmailReserveInfo = async (data: SendEmailBody) => {
  const req = await axios({
    method: "post",
    url: `${apiServices()}/send-booking-info-email`,
    data,
  });

  return { status: req.status, res: req.data };
};

import React, { useEffect, useState } from "react";
import "./styles.scss";
import CloseIcon from "../../../assets/icons/bx-x.svg";
import InputText from "../../Inputs/InputText";
import Button from "../../Buttons/Button";
import { FormYachtObj } from "../../../types/formYachtObj";
import SimpleSelect from "../../Selects/SimpleSelect";
import codesMock from "../../../mocks/countryCodes.json";
import { setupPhoneNumberInput } from "../../../utils/masks";
import { CountryCode } from "libphonenumber-js";

interface ListYachtFormProps {
  formInformationCallback: (obj: {
    userName: string;
    userEmail: string;
    location: string;
    yearYacht: string;
    make: string;
    modelYacht: string;
    userPhone: string;
  }) => void;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListYachtForm: React.FC<ListYachtFormProps> = (props) => {
  const { formInformationCallback, isOpenModal, setIsOpenModal } = props;
  const [userName, setUserName] = useState("");
  const [userNameWarning, setUserNameWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [yearYacht, setYearYacht] = useState("");
  const [yearYachtWarning, setYearYachtWarning] = useState(false);
  const [location, setLocation] = useState("");
  const [locationWarning, setLocationWarning] = useState(false);
  const [make, setMake] = useState("");
  const [makeWarning, setMakeWarning] = useState(false);
  const [modelYacht, setModelYacht] = useState("");
  const [modelYachtWarning, setModelYachtWarning] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [userPhoneWarning, setUserPhoneWarning] = useState(false);
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState("" as string);

  const inputPhoneHandler = (e: string) => {
    if (phoneCode) {
      let codeCountry = JSON.parse(phoneCode);

      const formattedNumber = setupPhoneNumberInput(
        codeCountry.code as CountryCode,
        e
      );

      setUserPhone(formattedNumber);

      setCountryCode(codeCountry.countryCode);
      // setUserPhone(`${e.replace(/\D/g, "")}`);
    } else {
      setUserPhone(e);
      setUserPhone(e.replace(/\D/g, ""));
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpenModal]);

  const submitFormHandler = () => {
    if (!userName) {
      setUserNameWarning(true);
      setTimeout(() => setUserNameWarning(false), 3000);
      return;
    }

    if (!userEmail) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    if (userEmail.indexOf("@") === -1 || userEmail.indexOf(".com") === -1) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    if (!location) {
      setLocationWarning(true);
      setTimeout(() => setLocationWarning(false), 3000);
      return;
    }

    if (!yearYacht) {
      setYearYachtWarning(true);
      setTimeout(() => setYearYachtWarning(false), 3000);
      return;
    }

    if (!make) {
      setMakeWarning(true);
      setTimeout(() => setMakeWarning(false), 3000);
      return;
    }

    if (!modelYacht) {
      setModelYachtWarning(true);
      setTimeout(() => setModelYachtWarning(false), 3000);
      return;
    }

    const formYachtObj = {
      userName,
      userEmail,
      location,
      yearYacht,
      make,
      modelYacht,
      userPhone: `${countryCode}${userPhone.replace(/\D/g, "")}`,
    };

    formInformationCallback(formYachtObj);
    closeModalHandler();
  };

  const closeModalHandler = () => {
    setUserName("");
    setUserEmail("");
    setYearYacht("");
    setLocation("");
    setMake("");
    setModelYacht("");
    setUserPhone("");
    setIsOpenModal(false);
  };

  const countryCodeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPhoneCode(e.target.value);
  };

  return (
    <>
      {isOpenModal && <div className="modal-overlay-list" />}
      <div id="list-yacht-form" className={isOpenModal ? "show-form" : ""}>
        <div className="header-form">
          <h3 className="header-form-title">
            Tell us a little about your yacht
          </h3>
          <p className="header-form-subtitle">
            This information will help us identify the rest of your boat
            details, which you can review later.{" "}
          </p>
        </div>
        <div className="body-form">
          <InputText
            type="text"
            value={userName}
            onChangeInput={setUserName}
            label="Name"
            isWarning={userNameWarning}
          />
          <InputText
            type="email"
            value={userEmail}
            onChangeInput={setUserEmail}
            label="Email"
            isWarning={userEmailWarning}
          />

          <p className="label-input-text">Phone</p>
          <div className="grid-column-phone">
            <SimpleSelect
              options={codesMock.map((code) => {
                return {
                  label: `${code.flag} ${code.code} ${code.countryCode}`,
                  value: JSON.stringify(code),
                };
              })}
              onChange={countryCodeHandler}
              value={phoneCode}
              defaultValueText={"Select"}
            />
            <InputText
              value={userPhone}
              onChangeInput={inputPhoneHandler}
              placeholder="Phone number"
            />
          </div>

          <InputText
            type="text"
            value={location}
            onChangeInput={setLocation}
            label="Location"
            isWarning={locationWarning}
          />

          <div className="grid-column">
            <InputText
              type="text"
              value={yearYacht}
              onChangeInput={(e) => setYearYacht(e)}
              label="Year"
              isWarning={yearYachtWarning}
            />

            <InputText
              type="text"
              value={make}
              onChangeInput={setMake}
              label="Make"
              isWarning={makeWarning}
            />
          </div>

          <InputText
            type="text"
            value={modelYacht}
            onChangeInput={setModelYacht}
            label="Model"
            isWarning={modelYachtWarning}
          />

          <div className="form-footer">
            <Button onClick={submitFormHandler} buttonStyle="primary">
              Get Started
            </Button>
            <Button onClick={closeModalHandler} buttonStyle="quaternary">
              Cancel
            </Button>
            <p className="footer-form-sub-button-text">
              You won't be charged yet
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListYachtForm;

import axios from "axios";
import { apiServices } from "../apiServices";

export const listSpecialCodes = async () => {
  const req = await axios({
    method: "get",
    url: `${apiServices()}/special_code`,
  });

  return { status: req.status, res: req.data };
};

import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";

interface SelectProps {
  options: OptionProps[];
  defaultValueText?: string;
  labelSelect?: string;
  iconLeft?: string;
  onChange: (a: any) => void;
  isWarning?: boolean;
  disabled?: boolean;
}

interface OptionProps {
  label: string;
  value: string;
  disabled?: boolean;
}

const Select: React.FC<SelectProps> = (props) => {
  const {
    options,
    defaultValueText = "-",
    labelSelect,
    iconLeft,
    onChange,
    isWarning,
    disabled,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionProps | null>(
    null
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option: OptionProps) => {
    setSelectedOption(option);
    onChange(option.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

	useEffect(() => {
    if (defaultValueText === "-") {
      setSelectedOption(null);
    } else {
      const matchingOption = options.find((option) => option.value === defaultValueText);
      if (matchingOption) {
        setSelectedOption(matchingOption);
      }
    }
  }, [defaultValueText]);

  return (
    <div ref={dropdownRef} className="select-dropdown">
      <p className={`select-label ${disabled && "disabled-label"}`}>
        {labelSelect}
      </p>
      <div
        onClick={toggleDropdown}
        id="select-default"
        className={`${isWarning && "isWarning"} ${
          disabled && "disabled-select"
        }`}
      >
        <div className="dropdown-header">
          {iconLeft && <img src={iconLeft} alt="icon" />}
          <p>
            {selectedOption
              ? selectedOption.label
              : disabled
              ? ""
              : defaultValueText}
          </p>
        </div>
        {isOpen && !disabled && (
          <div className="dropdown-wrapper">
            <ul className="dropdown-list">
              {options.map((option, index) => (
                <li
                  className={`${option.disabled ? "li-disabled" : ""}`}
                  key={index}
                  onClick={() => {
                    !option.disabled && handleOptionSelect(option);
                  }}
                >
                  <p>{option.label}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;

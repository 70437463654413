import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import TitleParagraph from "../../components/Texts/TitleParagraph";
import CarouselDefault from "../../components/Carousels/CarouselDefault";
import SlideCarousel from "../../components/Carousels/SlideCarousel";
import Details from "../../components/Details";
import PriceCard from "../../components/Cards/PriceCard";
import SpecificationsCard from "../../components/Cards/SpecificationsCard";
import DetailsCaption from "../../components/Details/DetailsCaption";
import { FormYachtObj } from "../../types/formYachtObj";
import { IToast } from "../../types/toast";
import { boats } from "../../mocks/boats";
import { IYachtDetails } from "../../types/yachtDetails";
import SpecificationsCard01 from "../../components/Cards/SpecificationsCard01";
import Details01 from "../../components/Details01";
import YachtBookingForm from "../../components/Forms/YachtBookingForm";
import { sendEmailReserve } from "../../api/reserves/sendEmailReserve";
import {
  EventDetails,
  formatGoogleCalendarUrl,
} from "../../utils/googleCalendarUrlFormatter";
import { sendEmailReserveInfo } from "../../api/reserves/sendEmailReserveInfo";
import { initGA, logEvent, logPageView } from "../../utils/analytics";
import { getReservesFromCalendar } from "../../api/reserves/getReservesFromCalendar";
import { ICalendarEvent } from "../../types/calendarEvent";
import { formatDateTimeObj } from "../../utils/formatDateTimeObj";
import { useParams } from "react-router-dom";

interface IExpDateTime {
  expDateStart: string;
  expDateEnd: string;
  expHourMinutesStart: string;
  expHourMinutesEnd: string;
}

const DetailPage: React.FC = () => {
  const [boatSpecifications, setBoatSpecifications] = useState<IYachtDetails>({
    images: [],
    name: "",
    prices: {
      card: "1,200",
      pricePerHour: 400,
    },
    captainPrices: {
      captainPricePerHour: 50,
    },
    routeName: "",
    showComingSoon: false,
    specification: "",
    texts: { paragraph: "", subTitle: "", title: "" },
    vehicleImg: "",
  });
  const [activeImageId, setActiveImageId] = useState<string | undefined>(
    undefined
  );
  const [openForm, setOpenForm] = useState(false);
  const [showPriceCard, setShowPriceCard] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 768);
  const [expObject, setExpObject] = useState<IExpDateTime[] | []>([]);

  let { yachtName } = useParams();

  useEffect(() => {
    initGA();

    const captureUTMParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const allParams: Record<string, string> = {};

      urlParams.forEach((value, key) => {
        allParams[key] = value;
      });

      if (Object.keys(allParams).length > 0) {
        localStorage.setItem("urlParams", JSON.stringify(allParams));

        logEvent("URL Params", "Capture", JSON.stringify(allParams));
      }
    };

    const cleanURL = () => {
      if (window.location.search) {
        const url = new URL(window.location.href);
        url.search = "";
        window.history.replaceState({}, document.title, url.toString());
      }
    };

    captureUTMParams();
    cleanURL();
    logPageView();
  }, []);

  useEffect(() => {
    let boatDetails = boats.filter((boat) => boat.routeName === yachtName);

    setBoatSpecifications(boatDetails[0]);
  }, [yachtName]);

  useEffect(() => {
    if (openForm) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openForm]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setShowPriceCard(true);
      } else {
        setShowPriceCard(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const scrollTarget = localStorage.getItem("scrollTarget");

    if (scrollTarget) {
      const checkElementAndScroll = () => {
        const element = document.querySelector(scrollTarget);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });

          localStorage.removeItem("scrollTarget");

          clearInterval(interval);
        }
      };

      const interval = setInterval(checkElementAndScroll, 100);
    }
  }, []);

  useEffect(() => {
    const listReserves = async () => {
      const response = await getReservesFromCalendar();

      const dateTimeObj = response.res.map((event: ICalendarEvent) => {
        return {
          expDateStart: formatDateTimeObj(event.start.dateTime).date,
          expHourMinutesStart: formatDateTimeObj(event.start.dateTime).time,
          expDateEnd: formatDateTimeObj(event.end.dateTime).date,
          expHourMinutesEnd: formatDateTimeObj(event.end.dateTime).time,
        } as IExpDateTime;
      });
      setExpObject(dateTimeObj);
    };

    listReserves();
  }, [boatSpecifications.texts.title]);

  const sumStartTimeAndDuration = (
    startTime: string | undefined,
    duration: string | undefined
  ) => {
    if (startTime) {
      let hour = String(
        Number(startTime?.split(":")[0]) + Number(duration?.replace("hrs", ""))
      );
      const min = startTime.split(" ")[0].split(":")[1];
      let period = "";
      if (Number(hour) >= 12) {
        period = startTime.split(" ")[1] === "AM" ? "PM" : "AM";
        hour = String(Number(hour) - 12);
      } else {
        period = startTime.split(" ")[1];
      }

      return `${hour}:${min} ${period}`;
    }
  };

  const handleImageClick = (img: { id: string; img: string }) => {
    setActiveImageId(img.id);
  };

  const formBookingSubmit = async (reserve: FormYachtObj) => {
    const eventDetails: EventDetails = {
      user_name: reserve.userName as string,
      user_email: reserve.userEmail,
      start_date: reserve.dateExpBegin as string,
      end_date: reserve.dateExpEnd || reserve.dateExpBegin || "",
      number_passenger: Number(reserve.passengerNumber),
      hours: reserve.duration ? reserve.duration : "",
      boat: boatSpecifications.name.replace("'", ""),
      user_phone: reserve.userPhone as string,
      end_hour:
        sumStartTimeAndDuration(reserve.startTime, reserve?.duration) || "",
      start_hour: reserve.startTime as string,
    };

    const calendarUrl = formatGoogleCalendarUrl(eventDetails);

    const reserveData = {
      name: reserve.userName as string,
      boat: boatSpecifications.name.replace("'", ""),
      hour: Number(reserve.duration?.replace("hrs", "")),
      number_passenger: Number(reserve.passengerNumber),
      start_date: reserve.dateExpBegin as string,
      end_date: reserve.dateExpEnd || reserve.dateExpBegin || "",
      google_calendar_url: calendarUrl,
      to: reserve.userEmail,
    };

    let viaLink = localStorage.getItem("urlParams");

    const reserveDataInfo = {
      name: reserve.userName as string,
      client_phone: reserve.userPhone as string,
      client_email: reserve.userEmail,
      boat: boatSpecifications.name.replace("'", ""),
      hour: reserve.duration ? Number(reserve.duration?.replace("hrs", "")) : 0,
      number_passenger: Number(reserve.passengerNumber),
      start_date: reserve.dateExpBegin as string,
      end_date: reserve.dateExpEnd || reserve.dateExpBegin || "",
      google_calendar_url: calendarUrl,
      to: reserve.userEmail,
      via_link: viaLink
        ? Object.values(JSON.parse(viaLink)).map((value) => value + ", ")
        : undefined,
    };

    const sendEmailRequest = await sendEmailReserve(reserveData);
    const sendEmailRequestInfo = await sendEmailReserveInfo(reserveDataInfo);

    if (
      sendEmailRequest.status === 200 &&
      sendEmailRequestInfo.status === 200
    ) {
      localStorage.clear();
    }
  };

  const bookYachtHandler = () => {
    setOpenForm((prev) => !prev);
  };

  return (
    <>
      <div id="detail-page">
        <LayoutDefault>
          <CarouselDefault
            imagesCarousel={boatSpecifications.images}
            activeImageId={activeImageId}
            infoText={{
              title: boats[0].texts.title,
              subTitle: boats[0].texts.subTitle,
              buttonText: "Instant Book",
              buttonClick: () => {
                const element = document.querySelector("#yacht-booking-form");
                if (element && isWideScreen) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
                if (!isWideScreen) {
                  bookYachtHandler();
                }
              },
            }}
          />
          <SlideCarousel
            imagesCarousel={boatSpecifications.images}
            onImageClick={handleImageClick}
          />

          <div className="boat-info-wrapper">
            <img
              loading="lazy"
              src={boatSpecifications.vehicleImg}
              alt="vehicle to book"
            />
            <div className="title-paragraph-wrapper">
              <TitleParagraph
                title={boatSpecifications.texts.title}
                paragraph={boatSpecifications.texts.paragraph}
                textAlign="center"
                paragraphColor="gray"
                isBannerTitle
              />
            </div>
          </div>
          <hr />
          <div className="detail-wrapper">
            {!isWideScreen ? (
              <div className="video-wrapper">
                <iframe
                  className="video-player"
                  src="https://www.youtube.com/embed/cVBMwjWEg_4?si=smC2FjuUaQZSUPdi"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
            <div className="detail-inner-wrapper">
              <div className="detail-left">
                {isWideScreen ? (
                  <div className="video-wrapper">
                    <iframe
                      className="video-player"
                      src="https://www.youtube.com/embed/cVBMwjWEg_4?si=smC2FjuUaQZSUPdi"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : null}
                <Details />

                {isWideScreen ? <DetailsCaption /> : null}
              </div>
              <div className="detail-right">
                {boatSpecifications.routeName === "2022-nx-horizon-40" ? (
                  <>
                    {isWideScreen ? (
                      <YachtBookingForm
                        isVisible
                        setVisible={setOpenForm}
                        headerFormTitle="Book this yacht"
                        headerFormSubTitle="Get ready to enjoy your day on the water"
                        priceReserveFor3hr={
                          boatSpecifications.prices.pricePerHour
                        }
                        captainPriceFor3hrs={
                          boatSpecifications.captainPrices.captainPricePerHour
                        }
                        formInformationCallback={formBookingSubmit}
                        expDateTime={expObject}
                      />
                    ) : null}
                    <SpecificationsCard />
                  </>
                ) : (
                  <SpecificationsCard01 />
                )}
              </div>
            </div>

            {!isWideScreen ? (
              <>
                <hr /> <DetailsCaption />
              </>
            ) : null}
          </div>
        </LayoutDefault>

        <YachtBookingForm
          isVisible={openForm}
          setVisible={setOpenForm}
          headerFormTitle="Book this yacht"
          headerFormSubTitle="Get ready to enjoy your day on the water"
          priceReserveFor3hr={boatSpecifications.prices.pricePerHour}
          captainPriceFor3hrs={
            boatSpecifications.captainPrices.captainPricePerHour
          }
          formInformationCallback={formBookingSubmit}
          expDateTime={expObject}
        />
      </div>
      {!isWideScreen && !openForm && showPriceCard && (
        <PriceCard
          price={boatSpecifications.prices.card}
          onClick={bookYachtHandler}
        />
      )}
    </>
  );
};

export default DetailPage;
